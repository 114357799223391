
import { RelatorioService } from '@/core/services/financeiro';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class RelatorioReceita extends Vue {
  service = new RelatorioService();
  item = new Relatorio();
  overlay: boolean = false;
  filter: any = {};

  mounted() {
    this.filter = this.$route.query;
    this.overlay = true;

    this.service.GetRelatorioContratoDigital(this.filter.empresasIds, this.filter.dataEmissaoInicial, this.filter.dataEmissaoFinal, this.filter.dataEnvioAssinaturaInicial, this.filter.dataEnvioAssinaturaFinal, this.filter.dataAssinaturaInicial, this.filter.dataAssinaturaFinal)
      .then(res => {
        this.item = res.data; // atribuir os dados do relatório à propriedade item
      })
      .catch(err => {
        if (!err.response) {
          this.$swal("Aviso", "Não foi possível acessar a API", "error");
        } else {
          this.$swal("Aviso", err.response.data, "error");
        }
      })
      .finally(() => {
        this.overlay = false;
      });
  }

  formatDate(dateString: string | null): string {
    if (dateString) {
      const date = new Date(dateString);
      return date.toLocaleDateString();
    } 
    return '-';
  }
}

class Relatorio {
  public contratoId: number = 0;
  public numero: number = 0;
  public empresaNome: string = "";
  public situacaoId: number = 0;
  public situacaoNome: string = "";
  public tipo: string = "";
  public dataEmissao: string = "";
  public dataEnvioAssinatura: string = "";
  public dataAssinatura: string = "";
}
